export interface ISDCode {
    code: string;
    country: string;
    pattern: string;
  }
  export const isdCodes: ISDCode[] =[
    { code: "+93", country: "Afghanistan", pattern: "^[0-9]{9}$" },
    { code: "+355", country: "Albania", pattern: "^[0-9]{9}$" },
    { code: "+213", country: "Algeria", pattern: "^[0-9]{9}$" },
    { code: "+1-684", country: "American Samoa", pattern: "^[0-9]{7}$" },
    { code: "+376", country: "Andorra", pattern: "^[0-9]{6}$" },
    { code: "+244", country: "Angola", pattern: "^[0-9]{9}$" },
    { code: "+1-264", country: "Anguilla", pattern: "^[0-9]{7}$" },
    { code: "+672", country: "Antarctica", pattern: "^[0-9]{6}$" },
    { code: "+1-268", country: "Antigua and Barbuda", pattern: "^[0-9]{7}$" },
    { code: "+54", country: "Argentina", pattern: "^[0-9]{10}$" },
    { code: "+374", country: "Armenia", pattern: "^[0-9]{8}$" },
    { code: "+297", country: "Aruba", pattern: "^[0-9]{7}$" },
    { code: "+61", country: "Australia", pattern: "^[0-9]{9}$" },
    { code: "+43", country: "Austria", pattern: "^[0-9]{10}$" },
    { code: "+994", country: "Azerbaijan", pattern: "^[0-9]{9}$" },
    { code: "+1-242", country: "Bahamas", pattern: "^[0-9]{7}$" },
    { code: "+973", country: "Bahrain", pattern: "^[0-9]{8}$" },
    { code: "+880", country: "Bangladesh", pattern: "^[0-9]{10}$" },
    { code: "+1-246", country: "Barbados", pattern: "^[0-9]{7}$" },
    { code: "+375", country: "Belarus", pattern: "^[0-9]{9}$" },
    { code: "+32", country: "Belgium", pattern: "^[0-9]{9}$" },
    { code: "+501", country: "Belize", pattern: "^[0-9]{7}$" },
    { code: "+229", country: "Benin", pattern: "^[0-9]{8}$" },
    { code: "+1-441", country: "Bermuda", pattern: "^[0-9]{7}$" },
    { code: "+975", country: "Bhutan", pattern: "^[0-9]{8}$" },
    { code: "+591", country: "Bolivia", pattern: "^[0-9]{8}$" },
    { code: "+387", country: "Bosnia and Herzegovina", pattern: "^[0-9]{8}$" },
    { code: "+267", country: "Botswana", pattern: "^[0-9]{8}$" },
    { code: "+55", country: "Brazil", pattern: "^[0-9]{10,11}$" },
    { code: "+246", country: "British Indian Ocean Territory", pattern: "^[0-9]{7}$" },
    { code: "+1-284", country: "British Virgin Islands", pattern: "^[0-9]{7}$" },
    { code: "+673", country: "Brunei", pattern: "^[0-9]{7}$" },
    { code: "+359", country: "Bulgaria", pattern: "^[0-9]{9}$" },
    { code: "+226", country: "Burkina Faso", pattern: "^[0-9]{8}$" },
    { code: "+257", country: "Burundi", pattern: "^[0-9]{8}$" },
    { code: "+855", country: "Cambodia", pattern: "^[0-9]{9}$" },
    { code: "+237", country: "Cameroon", pattern: "^[0-9]{9}$" },
    { code: "+1", country: "Canada", pattern: "^[0-9]{10}$" },
    { code: "+238", country: "Cape Verde", pattern: "^[0-9]{7}$" },
    { code: "+1-345", country: "Cayman Islands", pattern: "^[0-9]{7}$" },
    { code: "+236", country: "Central African Republic", pattern: "^[0-9]{8}$" },
    { code: "+235", country: "Chad", pattern: "^[0-9]{8}$" },
    { code: "+56", country: "Chile", pattern: "^[0-9]{9}$" },
    { code: "+86", country: "China", pattern: "^[0-9]{11}$" },
    { code: "+61", country: "Christmas Island", pattern: "^[0-9]{9}$" },
    { code: "+61", country: "Cocos Islands", pattern: "^[0-9]{9}$" },
    { code: "+57", country: "Colombia", pattern: "^[0-9]{10}$" },
    { code: "+269", country: "Comoros", pattern: "^[0-9]{7}$" },
    { code: "+682", country: "Cook Islands", pattern: "^[0-9]{5}$" },
    { code: "+506", country: "Costa Rica", pattern: "^[0-9]{8}$" },
    { code: "+385", country: "Croatia", pattern: "^[0-9]{9}$" },
    { code: "+53", country: "Cuba", pattern: "^[0-9]{8}$" },
    { code: "+599", country: "Curacao", pattern: "^[0-9]{7}$" },
    { code: "+357", country: "Cyprus", pattern: "^[0-9]{8}$" },
    { code: "+420", country: "Czech Republic", pattern: "^[0-9]{9}$" },
    { code: "+243", country: "Democratic Republic of the Congo", pattern: "^[0-9]{9}$" },
    { code: "+45", country: "Denmark", pattern: "^[0-9]{8}$" },
    { code: "+253", country: "Djibouti", pattern: "^[0-9]{8}$" },
    { code: "+1-767", country: "Dominica", pattern: "^[0-9]{7}$" },
    { code: "+1-809, +1-829, +1-849", country: "Dominican Republic", pattern: "^[0-9]{10}$" },
    { code: "+670", country: "East Timor", pattern: "^[0-9]{7}$" },
    { code: "+593", country: "Ecuador", pattern: "^[0-9]{9}$" },
    { code: "+20", country: "Egypt", pattern: "^[0-9]{10}$" },
    { code: "+503", country: "El Salvador", pattern: "^[0-9]{8}$" },
    { code: "+240", country: "Equatorial Guinea", pattern: "^[0-9]{9}$" },
    { code: "+291", country: "Eritrea", pattern: "^[0-9]{7}$" },
    { code: "+372", country: "Estonia", pattern: "^[0-9]{8}$" },
    { code: "+251", country: "Ethiopia", pattern: "^[0-9]{9}$" },
    { code: "+500", country: "Falkland Islands", pattern: "^[0-9]{5}$" },
    { code: "+298", country: "Faroe Islands", pattern: "^[0-9]{6}$" },
    { code: "+679", country: "Fiji", pattern: "^[0-9]{7}$" },
    { code: "+358", country: "Finland", pattern: "^[0-9]{9}$" },
    { code: "+33", country: "France", pattern: "^[0-9]{9}$" },
    { code: "+689", country: "French Polynesia", pattern: "^[0-9]{6}$" },
    { code: "+241", country: "Gabon", pattern: "^[0-9]{7}$" },
    { code: "+220", country: "Gambia", pattern: "^[0-9]{7}$" },
    { code: "+995", country: "Georgia", pattern: "^[0-9]{9}$" },
    { code: "+49", country: "Germany", pattern: "^[0-9]{10,11}$" },
    { code: "+233", country: "Ghana", pattern: "^[0-9]{9}$" },
    { code: "+350", country: "Gibraltar", pattern: "^[0-9]{8}$" },
    { code: "+30", country: "Greece", pattern: "^[0-9]{10}$" },
    { code: "+299", country: "Greenland", pattern: "^[0-9]{6}$" },
    { code: "+1-473", country: "Grenada", pattern: "^[0-9]{7}$" },
    { code: "+1-671", country: "Guam", pattern: "^[0-9]{7}$" },
    { code: "+502", country: "Guatemala", pattern: "^[0-9]{8}$" },
    { code: "+44-1481", country: "Guernsey", pattern: "^[0-9]{10}$" },
    { code: "+224", country: "Guinea", pattern: "^[0-9]{9}$" },
    { code: "+245", country: "Guinea-Bissau", pattern: "^[0-9]{7}$" },
    { code: "+592", country: "Guyana", pattern: "^[0-9]{7}$" },
    { code: "+509", country: "Haiti", pattern: "^[0-9]{8}$" },
    { code: "+504", country: "Honduras", pattern: "^[0-9]{8}$" },
    { code: "+852", country: "Hong Kong", pattern: "^[0-9]{8}$" },
    { code: "+36", country: "Hungary", pattern: "^[0-9]{9}$" },
    { code: "+354", country: "Iceland", pattern: "^[0-9]{7}$" },
    { code: "+91", country: "India", pattern: "^[0-9]{10}$" },
    { code: "+62", country: "Indonesia", pattern: "^[0-9]{10,11}$" },
    { code: "+98", country: "Iran", pattern: "^[0-9]{10}$" },
    { code: "+964", country: "Iraq", pattern: "^[0-9]{10}$" },
    { code: "+353", country: "Ireland", pattern: "^[0-9]{9}$" },
    { code: "+44-1624", country: "Isle of Man", pattern: "^[0-9]{10}$" },
    { code: "+972", country: "Israel", pattern: "^[0-9]{9}$" },
    { code: "+39", country: "Italy", pattern: "^[0-9]{10}$" },
    { code: "+225", country: "Ivory Coast", pattern: "^[0-9]{8}$" },
    { code: "+1-876", country: "Jamaica", pattern: "^[0-9]{7}$" },
    { code: "+81", country: "Japan", pattern: "^[0-9]{10}$" },
    { code: "+44-1534", country: "Jersey", pattern: "^[0-9]{10}$" },
    { code: "+962", country: "Jordan", pattern: "^[0-9]{9}$" },
    { code: "+7", country: "Kazakhstan", pattern: "^[0-9]{10}$" },
    { code: "+254", country: "Kenya", pattern: "^[0-9]{9}$" },
    { code: "+686", country: "Kiribati", pattern: "^[0-9]{5}$" },
    { code: "+383", country: "Kosovo", pattern: "^[0-9]{8}$" },
    { code: "+965", country: "Kuwait", pattern: "^[0-9]{8}$" },
    { code: "+996", country: "Kyrgyzstan", pattern: "^[0-9]{9}$" },
    { code: "+856", country: "Laos", pattern: "^[0-9]{8}$" },
    { code: "+371", country: "Latvia", pattern: "^[0-9]{8}$" },
    { code: "+961", country: "Lebanon", pattern: "^[0-9]{8}$" },
    { code: "+266", country: "Lesotho", pattern: "^[0-9]{8}$" },
    { code: "+231", country: "Liberia", pattern: "^[0-9]{7,8}$" },
    { code: "+218", country: "Libya", pattern: "^[0-9]{9}$" },
    { code: "+423", country: "Liechtenstein", pattern: "^[0-9]{7}$" },
    { code: "+370", country: "Lithuania", pattern: "^[0-9]{8}$" },
    { code: "+352", country: "Luxembourg", pattern: "^[0-9]{9}$" },
    { code: "+853", country: "Macau", pattern: "^[0-9]{8}$" },
    { code: "+389", country: "Macedonia", pattern: "^[0-9]{8}$" },
    { code: "+261", country: "Madagascar", pattern: "^[0-9]{9}$" },
    { code: "+265", country: "Malawi", pattern: "^[0-9]{9}$" },
    { code: "+60", country: "Malaysia", pattern: "^[0-9]{9,10}$" },
    { code: "+960", country: "Maldives", pattern: "^[0-9]{7}$" },
    { code: "+223", country: "Mali", pattern: "^[0-9]{8}$" },
    { code: "+356", country: "Malta", pattern: "^[0-9]{8}$" },
    { code: "+692", country: "Marshall Islands", pattern: "^[0-9]{7}$" },
    { code: "+222", country: "Mauritania", pattern: "^[0-9]{8}$" },
    { code: "+230", country: "Mauritius", pattern: "^[0-9]{8}$" },
    { code: "+262", country: "Mayotte", pattern: "^[0-9]{9}$" },
    { code: "+52", country: "Mexico", pattern: "^[0-9]{10}$" },
    { code: "+691", country: "Micronesia", pattern: "^[0-9]{7}$" },
    { code: "+373", country: "Moldova", pattern: "^[0-9]{8}$" },
    { code: "+377", country: "Monaco", pattern: "^[0-9]{8}$" },
    { code: "+976", country: "Mongolia", pattern: "^[0-9]{8}$" },
    { code: "+382", country: "Montenegro", pattern: "^[0-9]{8}$" },
    { code: "+1-664", country: "Montserrat", pattern: "^[0-9]{7}$" },
    { code: "+212", country: "Morocco", pattern: "^[0-9]{9}$" },
    { code: "+258", country: "Mozambique", pattern: "^[0-9]{9}$" },
    { code: "+95", country: "Myanmar", pattern: "^[0-9]{8,9}$" },
    { code: "+264", country: "Namibia", pattern: "^[0-9]{9}$" },
    { code: "+674", country: "Nauru", pattern: "^[0-9]{7}$" },
    { code: "+977", country: "Nepal", pattern: "^[0-9]{10}$" },
    { code: "+31", country: "Netherlands", pattern: "^[0-9]{9}$" },
    { code: "+599", country: "Netherlands Antilles", pattern: "^[0-9]{7}$" },
    { code: "+687", country: "New Caledonia", pattern: "^[0-9]{6}$" },
    { code: "+64", country: "New Zealand", pattern: "^[0-9]{8,9}$" },
    { code: "+505", country: "Nicaragua", pattern: "^[0-9]{8}$" },
    { code: "+227", country: "Niger", pattern: "^[0-9]{8}$" },
    { code: "+234", country: "Nigeria", pattern: "^[0-9]{10}$" },
    { code: "+683", country: "Niue", pattern: "^[0-9]{4}$" },
    { code: "+850", country: "North Korea", pattern: "^[0-9]{10}$" },
    { code: "+1-670", country: "Northern Mariana Islands", pattern: "^[0-9]{7}$" },
    { code: "+47", country: "Norway", pattern: "^[0-9]{8}$" },
    { code: "+968", country: "Oman", pattern: "^[0-9]{8}$" },
    { code: "+92", country: "Pakistan", pattern: "^[0-9]{10}$" },
    { code: "+680", country: "Palau", pattern: "^[0-9]{7}$" },
    { code: "+970", country: "Palestine", pattern: "^[0-9]{9}$" },
    { code: "+507", country: "Panama", pattern: "^[0-9]{8}$" },
    { code: "+675", country: "Papua New Guinea", pattern: "^[0-9]{8}$" },
    { code: "+595", country: "Paraguay", pattern: "^[0-9]{9}$" },
    { code: "+51", country: "Peru", pattern: "^[0-9]{9}$" },
    { code: "+63", country: "Philippines", pattern: "^[0-9]{10}$" },
    { code: "+64", country: "Pitcairn", pattern: "^[0-9]{9}$" },
    { code: "+48", country: "Poland", pattern: "^[0-9]{9}$" },
    { code: "+351", country: "Portugal", pattern: "^[0-9]{9}$" },
    { code: "+1-787, +1-939", country: "Puerto Rico", pattern: "^[0-9]{10}$" },
    { code: "+974", country: "Qatar", pattern: "^[0-9]{8}$" },
    { code: "+242", country: "Republic of the Congo", pattern: "^[0-9]{9}$" },
    { code: "+262", country: "Reunion", pattern: "^[0-9]{9}$" },
    { code: "+40", country: "Romania", pattern: "^[0-9]{9}$" },
    { code: "+7", country: "Russia", pattern: "^[0-9]{10}$" },
    { code: "+250", country: "Rwanda", pattern: "^[0-9]{9}$" },
    { code: "+590", country: "Saint Barthelemy", pattern: "^[0-9]{9}$" },
    { code: "+290", country: "Saint Helena", pattern: "^[0-9]{4}$" },
    { code: "+1-869", country: "Saint Kitts and Nevis", pattern: "^[0-9]{7}$" },
    { code: "+1-758", country: "Saint Lucia", pattern: "^[0-9]{7}$" },
    { code: "+590", country: "Saint Martin", pattern: "^[0-9]{9}$" },
    { code: "+508", country: "Saint Pierre and Miquelon", pattern: "^[0-9]{6}$" },
    { code: "+1-784", country: "Saint Vincent and the Grenadines", pattern: "^[0-9]{7}$" },
    { code: "+685", country: "Samoa", pattern: "^[0-9]{5,7}$" },
    { code: "+378", country: "San Marino", pattern: "^[0-9]{8}$" },
    { code: "+239", country: "Sao Tome and Principe", pattern: "^[0-9]{7}$" },
    { code: "+966", country: "Saudi Arabia", pattern: "^[0-9]{9}$" },
    { code: "+221", country: "Senegal", pattern: "^[0-9]{9}$" },
    { code: "+381", country: "Serbia", pattern: "^[0-9]{8,9}$" },
    { code: "+248", country: "Seychelles", pattern: "^[0-9]{7}$" },
    { code: "+232", country: "Sierra Leone", pattern: "^[0-9]{8}$" },
    { code: "+65", country: "Singapore", pattern: "^[0-9]{8}$" },
    { code: "+1-721", country: "Sint Maarten", pattern: "^[0-9]{7}$" },
    { code: "+421", country: "Slovakia", pattern: "^[0-9]{9}$" },
    { code: "+386", country: "Slovenia", pattern: "^[0-9]{8}$" },
    { code: "+677", country: "Solomon Islands", pattern: "^[0-9]{5}$" },
    { code: "+252", country: "Somalia", pattern: "^[0-9]{8}$" },
    { code: "+27", country: "South Africa", pattern: "^[0-9]{9}$" },
    { code: "+82", country: "South Korea", pattern: "^[0-9]{9,10}$" },
    { code: "+211", country: "South Sudan", pattern: "^[0-9]{9}$" },
    { code: "+34", country: "Spain", pattern: "^[0-9]{9}$" },
    { code: "+94", country: "Sri Lanka", pattern: "^[0-9]{9}$" },
    { code: "+249", country: "Sudan", pattern: "^[0-9]{9}$" },
    { code: "+597", country: "Suriname", pattern: "^[0-9]{7}$" },
    { code: "+47", country: "Svalbard and Jan Mayen", pattern: "^[0-9]{8}$" },
    { code: "+268", country: "Swaziland", pattern: "^[0-9]{8}$" },
    { code: "+46", country: "Sweden", pattern: "^[0-9]{9}$" },
    { code: "+41", country: "Switzerland", pattern: "^[0-9]{9}$" },
    { code: "+963", country: "Syria", pattern: "^[0-9]{9}$" },
    { code: "+886", country: "Taiwan", pattern: "^[0-9]{9}$" },
    { code: "+992", country: "Tajikistan", pattern: "^[0-9]{9}$" },
    { code: "+255", country: "Tanzania", pattern: "^[0-9]{9}$" },
    { code: "+66", country: "Thailand", pattern: "^[0-9]{9}$" },
    { code: "+228", country: "Togo", pattern: "^[0-9]{8}$" },
    { code: "+690", country: "Tokelau", pattern: "^[0-9]{4}$" },
    { code: "+676", country: "Tonga", pattern: "^[0-9]{5}$" },
    { code: "+1-868", country: "Trinidad and Tobago", pattern: "^[0-9]{7}$" },
    { code: "+216", country: "Tunisia", pattern: "^[0-9]{8}$" },
    { code: "+90", country: "Turkey", pattern: "^[0-9]{10}$" },
    { code: "+993", country: "Turkmenistan", pattern: "^[0-9]{8}$" },
    { code: "+1-649", country: "Turks and Caicos Islands", pattern: "^[0-9]{7}$" },
    { code: "+688", country: "Tuvalu", pattern: "^[0-9]{5}$" },
    { code: "+1-340", country: "U.S. Virgin Islands", pattern: "^[0-9]{10}$" },
    { code: "+256", country: "Uganda", pattern: "^[0-9]{9}$" },
    { code: "+380", country: "Ukraine", pattern: "^[0-9]{9}$" },
    { code: "+971", country: "United Arab Emirates", pattern: "^[0-9]{9}$" },
    { code: "+44", country: "United Kingdom", pattern: "^[0-9]{10}$" },
    { code: "+1", country: "United States", pattern: "^[0-9]{10}$" },
    { code: "+598", country: "Uruguay", pattern: "^[0-9]{8}$" },
    { code: "+998", country: "Uzbekistan", pattern: "^[0-9]{9}$" },
    { code: "+678", country: "Vanuatu", pattern: "^[0-9]{7}$" },
    { code: "+379", country: "Vatican", pattern: "^[0-9]{10}$" },
    { code: "+58", country: "Venezuela", pattern: "^[0-9]{10}$" },
    { code: "+84", country: "Vietnam", pattern: "^[0-9]{9,10}$" },
    { code: "+681", country: "Wallis and Futuna", pattern: "^[0-9]{5}$" },
    { code: "+212", country: "Western Sahara", pattern: "^[0-9]{9}$" },
    { code: "+967", country: "Yemen", pattern: "^[0-9]{9}$" },
    { code: "+260", country: "Zambia", pattern: "^[0-9]{9}$" },
    { code: "+263", country: "Zimbabwe", pattern: "^[0-9]{9}$" }
];
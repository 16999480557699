import { useAppSelector } from "../../../../redux/hooks";
// Redux stores
const Review = () => {
  const { attributes } = useAppSelector((state) => state.homepage);
  let imgBaseUrl = process.env.REACT_APP_API_IMG_URL;
  return (
    <>
      <div className="circle-blur-img">
        <img src="/assets/images/circle-blur-img.svg" alt="circle-blur-img" />
      </div>
      <div className="container">
        <div className="main-hdng text-center">
          <h2>{attributes.sec_9[0].h1}</h2>
          <p>{attributes.sec_9[0].h2}</p>
        </div>
        <div className="review-bx-area">
          {attributes.sec_9[0].review_t2.map((item, index) => (
            <div className="review-bx">
              <div className="reviewer-company">
                <img src="/assets/images/reviewer-company-logo1.svg" alt="reviewer-company-logo" />
                <h6>{attributes?.sec_1_rit?.[index]?.text}</h6>
              </div>
             
              <div className="rvw-str-new-sd" style={{ padding: "9px 2px" }}>
                <img src="/assets/images/banner-review-stars.svg" alt="rating-star-ico" />
              </div>
              <div className="reviewer-details-bx">
                <div className="reviewer-img">
                  <img
                    // src="/assets/images/reviewer-img1.png"
                    src={imgBaseUrl ? imgBaseUrl + item.image.data?.attributes?.url : "/assets/images/reviewer-img1.png"}
                    alt="reviewer-img"
                  />
                </div>
                <div className="reviewer-details">
                  <h6>{item.assignment_writer_name}</h6>
                  <p>{item.assignment_writer_education}</p>
                </div>
              </div>
              <div className="review-date">
                <img src="/assets/images/calender-ico-2.svg" alt="calender-ico-2" />
                <span>31 Jul 2024</span>
              </div>
              <div className="review-text">
                <p>{item.description}</p>
              </div>
              <a href="#!" className="reviewviewdetails-btn">
                <img src="/assets/images/reviewviewdetails-btn.svg" alt="reviewviewdetails-btn" />
              </a>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Review;

import React, { useEffect, useState } from "react";

// API functions
import { FetchPaymentDetails } from "../../helpers/api_helpers";

// Components
import PageBanner from "../components/layout/pages/PageBanner";

const Payment = () => {
  const [nationalPayments, setNationalPayments] = useState<any>([]);
  const [internationalPayments, setInternationalPayments] = useState([]);
  const [toggleOption, setToggleOption] = useState("National");
  const [Qrstatus, setQrstatus] = useState(false);
  const [showId, setShowId] = useState<any>("");
  const [payment_title, setPaymentTitle] = useState<any>({});

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    fetchAndFilterPaymentDetails();
  }, []);

  async function fetchAndFilterPaymentDetails() {
    try {
      const response: any = await FetchPaymentDetails({
        urlPath: "banks?populate=deep",
        method: "GET",
      });
      const responses: any = await FetchPaymentDetails({
        urlPath: "payment-page?populate=deep",
        method: "GET",
      });
      setPaymentTitle(responses.data.attributes);
      console.log("responses=====>", responses);
      if (response && Array.isArray(response.data)) {
        console.log("Fetched Payment Details:", response.data);

        // Separate payments into National and International
        const national = response.data.filter((payment: any) => payment?.attributes?.type === "Indian-Payment");
        const international = response.data.filter((payment: any) => payment?.attributes?.type && payment.attributes.type !== "Indian-Payment");

        // Extract specific fields from national and add to international
        const nationalMappedToInternational = national.map((payment: any) => {
          const { BAN_For_European_Transactions, Bank_Branch_Address, Bank_Name, Routing_Number_For_US_Transfers, SWIFT_BIC_Code_For_International } = payment.attributes;
          console.log("national======> 1", payment.attributes);
          return {
            attributes: {
              Account_Name: payment.attributes.Account_Name || "N/A",
              BAN_For_European_Transactions: BAN_For_European_Transactions || "N/A",
              Bank_Branch_Address: Bank_Branch_Address || "N/A",
              Bank_Name: Bank_Name || "N/A",
              Routing_Number_For_US_Transfers: Routing_Number_For_US_Transfers || "N/A",
              SWIFT_BIC_Code_For_International: SWIFT_BIC_Code_For_International || "N/A",
              qr_code: payment.attributes.qr_code.data.attributes.url,
            },
            id: payment.id,
          };
        });

        const updatedInternational: any = [...international, ...nationalMappedToInternational];

        setNationalPayments(national);
        setInternationalPayments(updatedInternational);
      } else {
        console.error("Invalid response:", response);
      }
    } catch (error) {
      console.error("Error fetching payment details:", error);
    }
  }

  const handleQr = (id: any) => {
    setShowId(id);
    setQrstatus(!Qrstatus);
  };
  console.log("nationalsss======>", nationalPayments[showId]?.attributes.qr_code.data.attributes.url);
  return (
    <>
      {/* Page Banner Area Start */}
      {payment_title.Sec_1_i_banner && (
        <PageBanner
          title={payment_title.Sec_1_i_banner.heading}
          description={payment_title.Sec_1_i_banner.subheading}
          reviewSites={payment_title.Sec_1_i_banner.inner_banner_review}
          // className="experts-inner-banner"
        />
      )}
      <div className="container mt-5">
        <div className="switch-container">
          <label className="switch">
            <span
              className="label-text"
              style={{
                fontWeight: `${toggleOption === "National" ? "700" : "300"}`,
                marginRight: "10px",
              }}
            >
              National
            </span>
            <input type="checkbox" checked={toggleOption === "National"} onChange={() => setToggleOption(toggleOption === "National" ? "International" : "National")} />
            <span className="slider"></span>
            <span
              className="label-text"
              style={{
                fontWeight: `${toggleOption === "International" ? "700" : "300"}`,
              }}
            >
              International
            </span>
          </label>
        </div>
      </div>

      <section className="pt-4">
        <div className="container">
          <div className="row">
            <div className="col-lg-9 mx-auto">
              <div className="paymentbox-wrappert-cont p-4">
                <div className="row gx-3 gy-4 justify-content-center">
                  {toggleOption === "National" && nationalPayments.length > 0 ? (
                    nationalPayments.map((payment: any, index: number) => {
                      const { Account_Name, Account_Number, Bank_Name, Bank_Branch_Name, IFSC_Code_For_India, upi_id, qr_code } = payment.attributes;
                      console.log("national======> 2", qr_code);
                      return (
                        <div key={payment.id || index} className="col-lg-6">
                          <div className="payment-dtls-bx">
                            <table>
                              <tbody>
                                <tr>
                                  <th>Account Name</th>
                                  <td>{Account_Name || "N/A"}</td>
                                </tr>
                                <tr>
                                  <th>Account Number</th>
                                  <td>{Account_Number || "N/A"}</td>
                                </tr>
                                <tr>
                                  <th>Bank Name</th>
                                  <td>{Bank_Name || "N/A"}</td>
                                </tr>
                                <tr>
                                  <th>Bank Branch Name</th>
                                  <td>{Bank_Branch_Name || "N/A"}</td>
                                </tr>
                                <tr>
                                  <th>IFSC Code for India</th>
                                  <td>{IFSC_Code_For_India || "N/A"}</td>
                                </tr>
                                <tr>
                                  <th>UPI ID</th>
                                  <td
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                    }}
                                  >
                                    {upi_id || "N/A"}
                                    <button className={`toggle-button active`} style={{ margin: "10px 0px" }} onClick={() => handleQr(index)}>
                                      <span>View Qr Code</span>
                                    </button>
                                    {/* <button type="button" className="btn btn-warning" >
                              View Qr Codey
                        </button> */}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      );
                    })
                  ) : toggleOption === "International" && internationalPayments.length > 0 ? (
                    internationalPayments.map((payment: any, index: number) => {
                      const {
                        Account_Name,

                        Bank_Name,
                        Bank_Branch_Address,
                        BAN_For_European_Transactions,
                        SWIFT_BIC_Code_For_International,
                        Routing_Number_For_US_Transfers,
                      } = payment.attributes;

                      return (
                        <div key={payment.id || index} className="col-lg-6">
                          <div className="payment-dtls-bx">
                            <table>
                              <tbody>
                                <tr>
                                  <th>Account Name</th>
                                  <td>{Account_Name || "N/A"}</td>
                                </tr>
                                {/* <tr>
                            <th>Account Number</th>
                            <td>{Account_Number || "N/A"}</td>
                          </tr> */}
                                <tr>
                                  <th>Bank Name</th>
                                  <td>{Bank_Name || "N/A"}</td>
                                </tr>
                                <tr>
                                  <th>Bank Branch Address</th>
                                  <td>{Bank_Branch_Address || "N/A"}</td>
                                </tr>
                                <tr>
                                  <th>BAN for European Transactions</th>
                                  <td>{BAN_For_European_Transactions || "N/A"}</td>
                                </tr>
                                <tr>
                                  <th>Routing Number for US Transfers</th>
                                  <td>{Routing_Number_For_US_Transfers || "N/A"}</td>
                                </tr>
                                <tr>
                                  <th>SWIFT BIC Code for International</th>
                                  <td>{SWIFT_BIC_Code_For_International || "N/A"}</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <p>No payment details available for the selected option.</p>
                  )}
                  {Qrstatus && (
                    <div
                      className="modal fade show"
                      style={{
                        display: "block",
                        marginTop: "unset",
                        backgroundColor: "rgba(0, 0, 0, 0.5)",
                      }}
                      tabIndex={-1}
                      aria-labelledby="qrModalLabel"
                      aria-hidden="true"
                    >
                      <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5 className="modal-title" id="qrModalLabel">
                              QR Code
                            </h5>
                            <button type="button" className="btn-close" onClick={() => setQrstatus(false)}></button>
                          </div>
                          <div className="modal-body text-center d-flex justify-content-center align-items-center">
                            <img src={`https://admin.yourassignmenthelp.uk/${nationalPayments[showId]?.attributes.qr_code.data.attributes.url}`} alt="QR Code" className="img-fluid" />
                          </div>
                          <div className="modal-footer text-black">
                            <button
                              // type="button"
                              className={`toggle-button active`}
                              onClick={() => setQrstatus(false)}
                            >
                              <span>Close</span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style={{width:"100%", display:"flex", justifyContent:"center", alignItems:"center"}}>

        <p style={{padding:"20px"}}>
          <strong>Note:</strong> Kindly upload the payment screenshot by navigating to the
          <strong style={{marginLeft:"2px"}}>dashboard</strong> section for verification
          <span style={{ marginLeft: "10px" , cursor:"pointer"}}>
           
          <a href="https://yourassignmenthelp.uk/email/order-guide.pdf" target="_blank" >
          <i className="fas fa-external-link-alt" aria-hidden="true"></i>
</a>
          </span>

        </p>
        </div>
      </section>
    </>
  );
};

export default Payment;

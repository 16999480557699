import { useEffect, useState, Suspense } from "react";
import { FetchPrivacyPolicy } from "../../helpers/api_helpers";
import LazyLoader from "../components/layout/common/LazyLoader";
import PageBanner from "../components/layout/pages/PageBanner";

const RefundPolicy = () => {
  const [privacyPolicy, setPrivacyPolicy] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  // Fetch privacy policy on component mount
  useEffect(() => {
    const fetchPrivacyPolicy = async () => {
      try {
        
        let response = await FetchPrivacyPolicy({
          urlPath: "refund-policy?populate=deep",
          method: "GET",
        });
        if (!response) {
          throw new Error("Failed to fetch privacy policy");
        }
        setPrivacyPolicy(response);
      } catch (err: any) {
        setError(err.message);
        console.error("Error fetching privacy policy:", err);
      } finally {
        setIsLoading(false); 
      }
    };

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    fetchPrivacyPolicy();
  }, []);

  
  if (isLoading) {
    return (
      <LazyLoader
        pageBannerTitle={
          privacyPolicy?.data?.attributes?.sec_2_h1 || "Loading..."
        }
        pageBannerDescription={
          privacyPolicy?.data?.attributes?.sec_1_i_banner.subheading || ""
        }
        pageBannerReviewSites={
          privacyPolicy?.data?.attributes?.sec_1_i_banner.inner_banner_review ||
          []
        }
      />
    );
  }

  // Display error message if data failed to load
  if (error) {
    return (
      <div className="error-container">
        <h2>Error</h2>
        <p>{error}</p>
      </div>
    );
  }
console.log("inner_banner_review=====>",  privacyPolicy)
  return (
    <>
      {/* Page banner area */}
      <PageBanner title={privacyPolicy.data.attributes.sec_2_h1} description={privacyPolicy.data.attributes.sec_1_i_banner.subheading} reviewSites={privacyPolicy.data.attributes.sec_1_i_banner.inner_banner_review} />

      {/* Privacy policy content */}
      <div className="wrapper privacypage-container">
        <div className="container">
          <div
            className="footerpg"
            dangerouslySetInnerHTML={{
              __html: privacyPolicy.data.attributes.sec_2_description,
            }}
          ></div>
        </div>
      </div>
    </>
  );
};

export default RefundPolicy;

import { useEffect } from "react";
import {Link } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { useNavigate, useParams , useLocation} from "react-router-dom";
// Helpers
import qs from "qs";

// Redux actions
import { getSampleAnswerList } from "../../redux/slices/SamplePageSlice";
import { getSamplePage } from "../../redux/slices/SamplePageSlice";
// Components
import PageBanner from "../components/layout/pages/PageBanner";
import ReactPaginate from "react-paginate";
import SubmitAssignmentForm from "../components/layout/pages/SubmitAssignmentForm";
interface PaginationOnChangeEvent {
  selected: number;
}

const SampleAnswer = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  console.log(params.cat_name);
  console.log(params.sub_cat_id);
  const dispatch = useAppDispatch();
 const {  samplePageData } = useAppSelector((state) => state.samplePage);
  // Redux stores
  const { sampleAnswerListData } = useAppSelector((state) => state.samplePage);

  const queryFilters = {
    $and: [
      {
        sample_category: {
          category_name: {
            $eq: params.cat_name,
          },
        },
      },
      {
        sample_sub_category: {
          id: {
            $eq: params.sub_cat_id,
          },
        },
      },
    ],
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    const query = qs.stringify(
      {
        filters: queryFilters,
      },
      {
        encodeValuesOnly: true, // prettify URL
      }
    );
 dispatch(getSamplePage());
    dispatch(getSampleAnswerList(query));
  }, []);
  const handleClick = (id :any) => {
    console.log("id=====>", id)
    navigate(`${location.pathname}/${id}`); 
  }
  console.log("sampleAnswerListData===>", sampleAnswerListData);

  return (
    <>
      {/* page banner area start */}
     
      <PageBanner title={samplePageData.sec_1_i_banner[0].heading} description={samplePageData.sec_1_i_banner[0].subheading} reviewSites={samplePageData.sec_1_i_banner[0].inner_banner_review} className="experts-inner-banner" />
      {/* page banner area end */}

      {/* wrapper area start */}
      <div className="wrapper">
        <section className="sample-ans-sd">
          <div className="container">
            <div className="row">
              <div className="col-lg-7 free-samples-manleft-wrapper">
                {sampleAnswerListData.data.length > 0 ? (
                  <>
                    <div className="row align-items-center" >
                      <div className="col-sm-7 col-md-8 col-lg-8">
                        <div className="main-hdng">
                          <h2>
                            {
                              sampleAnswerListData.data[0]?.attributes
                                ?.sample_sub_category?.data.attributes
                                ?.sub_category_name
                            }
                          </h2>
                        </div>
                      </div>
                      <div className="col-sm-5 col-md-4 col-lg-4">
                        <div className="btn-group btn-group-andsd">
                          <Link
                            to="/sample"
                            className=" btn-1 hover-filled-slide-down back-link"
                          >
                            <span>
                              <i className="fa-solid fa-arrow-left"></i> Back to
                              Sample
                            </span>
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div  className="co-bx-sd-area"  >
                      {sampleAnswerListData.data.map((item, index) => (
                        <div className="co-bx" onClick={() => handleClick(item.attributes.course_code)} style={{cursor:"pointer"}}>
                          <div className="co-bx-top">
                            <h5>{item.attributes.heading}</h5>
                            {item?.attributes.description.map((item, index) => {
                              if (item.type === "paragraph") {
                                return item.children.map((it, idx) => (
                                  <>
                                    <p key={`${index}_${idx}`}>{it.text}</p>
                                  </>
                                ));
                              }
                            })}
                          </div>
                          <div className="co-bx-bottom">
                           
                            <div className="co-bx-bottom-right">
                              <div className="co-bx-bottom-right-single">
                                <img
                                  src="/assets/images/cbbrs-img-1.svg"
                                  alt="icon"
                                />
                                <span>
                                  Published{" "}
                                  <strong>
                                    {new Date(
                                      item.attributes.publishedAt
                                    ).toDateString()}
                                  </strong>{" "}
                                </span>
                              </div>
                              <div className="co-bx-bottom-right-single">
                                <img
                                  src="/assets/images/cbbrs-img-2.svg"
                                  alt="icon"
                                />
                                <span>
                                  Pages <strong>35</strong> Words{" "}
                                  <strong>8860</strong>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                    <div className="mb-5">
                      <ReactPaginate
                        breakLabel="..."
                        nextLabel="Next"
                        previousLabel="Previous"
                        pageLinkClassName="page-link"
                        previousClassName="page-item"
                        previousLinkClassName="page-link"
                        nextClassName="page-item"
                        nextLinkClassName="page-link"
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                        containerClassName="pagination pagination-sdnew pagination-sdnew2"
                        activeClassName="active"
                        onPageChange={(event: PaginationOnChangeEvent) => {
                          const query = qs.stringify(
                            {
                              filters: queryFilters,
                              pagination: {
                                page: event.selected + 1,
                                pageSize: 15,
                              },
                            },
                            {
                              encodeValuesOnly: true, // prettify URL
                            }
                          );

                          dispatch(getSampleAnswerList(query));
                        }}
                        pageRangeDisplayed={1}
            marginPagesDisplayed={1} 
                        pageCount={
                          sampleAnswerListData.meta.pagination.pageCount
                        }
                        renderOnZeroPageCount={null}
                      />
                    </div>
                  </>
                ) : (
                  <div  className="co-bx-sd-area"  style={{padding:"42px 0px"}}>
                  <div className="sd-not-found">
                    <img
                      src="/assets/images/no-data-icon-2.svg"
                      alt="no-data-icon"
                    />
                    <h4>No Data Found</h4>
                    <div className="btn-group mb-5">
                      <Link
                        to="/sample"
                        className="custombtn btn-1 hover-filled-slide-down"
                      >
                        <span>Back to Sample page</span>
                      </Link>
                    </div>
                  </div>
                  </div>
                )}
              </div>
              <div className="col-lg-5">
                <div className="real-experts-area">
                  <div className="real-experts-bx p-4 mb-5">
                    <div className="home-banner-form">
                      <div className="row justify-content-center">
                        <div>
                          <SubmitAssignmentForm />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default SampleAnswer;

import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { useNavigate, useParams, useLocation } from "react-router-dom";
// Utils
import { toast } from "react-hot-toast";
import { getSampleAnswerList } from "../../redux/slices/SamplePageSlice";
import _ from "lodash";

// Constants


// API functions
import { ApiHelperFunction } from "../../helpers/api_helpers";

// Redux actions
import { getSamplePage } from "../../redux/slices/SamplePageSlice";
import qs from "qs";
// Models
import { ApiFuncArgProps } from "../../models/apiFuncHelpers";
import SubmitAssignmentForm from "../components/layout/pages/SubmitAssignmentForm";
// Components
import PageBanner from "../components/layout/pages/PageBanner";

import { isApiErrorResponse } from "../../helpers/typeguards";



const AnswerDescriptionSample = () => {
   const {  samplePageData } = useAppSelector((state) => state.samplePage);
  const dispatch = useAppDispatch();
  const params: any = useParams();
  // Redux stores
  const { sampleAnswerListData } = useAppSelector((state) => state.samplePage);
  const [list, setList] = useState<any>(null);
const [categories, setcategories] = useState<any>(null);
   const fetchSubCategories = async (query?: string) => {
      let response: any;
      if (query) {
        response = await ApiHelperFunction({
          urlPath: `/sample-sub-categories?populate=deep&${query}`,
          method: "GET",
        } as ApiFuncArgProps);
      } else {
        response = await ApiHelperFunction({
          urlPath: "/sample-sub-categories?populate=deep",
          method: "GET",
        } as ApiFuncArgProps);
      }
  
      if (isApiErrorResponse(response)) {
        toast.error(response.error.message);
      } else if (response.data) {
        let sampleCategories = _.groupBy(response.data?.data, (item) => item?.attributes?.sample_category?.data?.attributes?.category_name);
        setcategories({ ...sampleCategories } as any);
      } else {
        toast.error("Unexpele sample category response");
      }
    };


  const queryFilters = {
    $and: [
      {
        sample_category: {
          category_name: {
            $eq: params.cat_name,
          },
        },
      },
      {
        sample_sub_category: {
          id: {
            $eq: params.sub_cat_id,
          },
        },
      },
    ],
  };
  useEffect(() => {
    const query = qs.stringify(
      {
        filters: queryFilters,
      },
      {
        encodeValuesOnly: true, // Prettify URL
      }
    );

    dispatch(getSampleAnswerList(query));
    dispatch(getSamplePage());
    fetchSubCategories();
  }, [dispatch, params.cat_name, params.sub_cat_id]);

  // Set list when sampleAnswerListData updates
  useEffect(() => {
    if (sampleAnswerListData?.data) {
      const matchedItem = sampleAnswerListData.data.find((item) => item.attributes.course_code === params.id);
      setList(matchedItem || null);
    }
  }, [sampleAnswerListData, params.id]);

  if (list === null || categories === null) {
    return <></>;
  }
console.log("categories====>", samplePageData)
  return (
    <>
      {/* page banner area start */}
      <PageBanner title={samplePageData.sec_1_i_banner[0].heading} description={samplePageData.sec_1_i_banner[0].subheading} reviewSites={samplePageData.sec_1_i_banner[0].inner_banner_review} className="experts-inner-banner" />
      {/* page banner area end */}

      {/* wrapper area start */}
      <div className="wrapper mb-5">
        <section className="cntcus-sec">
          <div className="container">
            <div className="row">
              <div className="col-lg-7 free-samples-manleft-wrapper">
                <div className="main-hdng" style={{ marginBottom: "unset" }}>
                  <h4>{list.attributes.heading}</h4>
                </div>
                {list.attributes.description.map((item: any) => {
                  if (item.type === "paragraph") {
                    return item.children.map((elem: any, childIndex: any) => <p key={childIndex}>{elem.text}</p>);
                  }
                  return <></>;
                })}
              </div>

              <div className="col-lg-5">
                <div className="real-experts-area">
                  <div className="real-experts-bx p-4 mb-5">
                    <div className="home-banner-form">
                      <div className="row justify-content-center">
                        <div>
                          <SubmitAssignmentForm />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="related-topic-box p-4">
                  <h4>Related Topics</h4>
                 
                  {categories[params.cat_name].map((items :any, index:any) => (
                   items.id !== Number(params.sub_cat_id) &&
                    <ul key={index}>
                      <li>
                        <a href={`/sample-answer/${params.cat_name}/${items.id}`}>{items.attributes.sub_category_name}</a>
                      </li>
                    </ul>
                  ))}
                </div>

                <div className="related-topic-box p-4">
                  <h4>Related Essays</h4>
                  {sampleAnswerListData.data.map((items, index) => (
                    items.attributes.course_code !== params.id &&
                    <ul key={index}>
                      <li>
                        <a href={`/sample-answer/${params.cat_name}/${params.sub_cat_id}/${items.attributes.course_code}`}>{items.attributes.heading}</a>
                      </li>
                    </ul>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      {/* wrapper area end */}
    </>
  );
};

export default AnswerDescriptionSample;

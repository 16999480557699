import { useEffect, useState } from "react";

// Protections
import { LoginProtected } from "../protections";

// Components
import { Routes, Route, useLocation } from "react-router-dom";
// import Login from "../pages/Login";
import ComingSoon from "../views/components/layout/common/ComingSoon";
import Layout from "../layout/Index";
import Homepage from "../views/pages/Homepage";
import RefundPolicy from "../views/pages/RefundPolicy";
import PrivacyPolicy from "../views/pages/PrivacyPolicy";
import FairUsePolicy from "../views/pages/FairPolicy";
import NotFound from "../views/pages/NotFound";
import Experts from "../views/pages/Experts";
import LazyReviewPage from "../views/pages/lazy_pages/LazyReviewPage";
import AboutUs from "../views/pages/AboutUs";
import { GrammarChecker, ParaphrasingTool, PlagiarismChecker, FactoringCalculator, EquationSolver } from "../views/pages/academic_tools";
import DashboardLayout from "../layout/DashboardLayout";
import Dashboard from "../views/pages/dashboard/Index";
import AllOrders from "../views/pages/dashboard/AllOrders";
import SayNoToAi from "../views/pages/dashboard/SayNoToAi";
import { DashboardGrammarChecker, DashboardParaphrasingTool, DashboardPlagiarismChecker, DashboardFactoringCalculator, DashboardEquationSolver } from "../views/pages/dashboard/dashboard_services";
import UserProfile from "../views/pages/users/Profile";
import MentorProfile from "../views/pages/mentor/MentorProfile";
import MentorAnswer from "../views/pages/mentor/MentorAnswer";
import Orders from "../views/pages/orders/Orders";
import Sample from "../views/pages/Sample";
import SampleAnswer from "../views/pages/SampleAnswer";
import AnswerDescriotionSample from "../views/pages/AnswerDescriotionSample";
import SubmitNewAssigment from "../views/pages/services/SubmitNewAssigment";
import {
  WriteMyPaper,
  MbaAssignment,
  WriteMyEssay,
  CheapEssay,
  EssayHelp,
  ResearchProposalWriting,
  ResearchPaperWriting,
  GhostWriter,
  AcademicWriting,
  ProgramminHelp,
  AssessmentHelp,
  DoMyAssignment,
  CollegeHomeworkHelp,
  UrgentHelp,
  PaySomeoneForHW,
  DoMyCoursework,
  DoMyHomework,
  TakeMyOnlineClass,
  TakeMyOnlineExam,
  DissertationHelp,
  TermPaperHelp,
  HomeworkHelp,
  CaseStudyHelp,
  CourseworkHelp,
  ThesisHelp,
  Powerpoint,
} from "../views/pages/services";
import Blogs from "../views/pages/Blogs";
import Payment from "../views/pages/Payment";
import BlogDetails from "../views/pages/BlogDetails";
import Referencing from "../views/pages/Referencing";
import FreeSamples from "../views/pages/FreeSamples";
// import SamplePage from "../views/pages/SamplePage";
import FAQ from "../views/pages/FAQ";
import ContactUs from "../views/pages/ContactUs";
interface MetaAttributes {
  url: string;
  meta_title: string;
  meta_descriptions: string;
}

interface MetaData {
  id: number;
  attributes: MetaAttributes;
}
const AppRoutes = () => {
  const location = useLocation();
  const [metaData, setMetaData] = useState<MetaData[]>([]);
  let API_URL: string | undefined = process.env.REACT_APP_API_BASE_URL;
 

  useEffect(() => {
    const fetchMetaData = async () => {
      try {
        const response = await fetch(`${API_URL}/seos`);
        const responseData = await response.json();

        if (Array.isArray(responseData.data)) {
          setMetaData(responseData.data);
        } else {
          console.error("Invalid data format, expected 'data' to be an array");
        }
      } catch (error) {
        console.error("Error fetching metadata:", error);
      }
    };

    fetchMetaData();
  }, []);

  useEffect(() => {
    if (Array.isArray(metaData)) {
    
      let API_IMG_URL = `https://yourassignmenthelp.uk`;
      const currentMeta = metaData.find((element) => element.attributes.url === `${API_IMG_URL}${location.pathname}`);

      if (currentMeta) {
     
        document.title = currentMeta.attributes.meta_title || "Default Title";

       
        const metaDescription = document.querySelector("meta[name='description']");
        if (metaDescription) {
          metaDescription.setAttribute("content", currentMeta.attributes.meta_descriptions);
        } else {
          const newMeta = document.createElement("meta");
          newMeta.name = "description";
          newMeta.content = currentMeta.attributes.meta_descriptions;
          document.head.appendChild(newMeta);
        }

      
        const ogTags = [
          { property: "og:type", content: "website" },
          { property: "og:title", content: currentMeta.attributes.meta_title },
          { property: "og:description", content: currentMeta.attributes.meta_descriptions },
          { property: "og:url", content: currentMeta.attributes.url },
          { property: "og:image", content: `${API_IMG_URL}/assets/images/logo.svg` },
          { property: "og:site_name", content: currentMeta.attributes.meta_title },
        ];

        ogTags.forEach(({ property, content }) => {
          const tag = document.querySelector(`meta[property='${property}']`);
          if (tag) {
            tag.setAttribute("content", content);
          } else {
            const newTag = document.createElement("meta");
            newTag.setAttribute("property", property);
            newTag.setAttribute("content", content);
            document.head.appendChild(newTag);
          }
        });

      
        const twitterTags = [
          { name: "twitter:card", content: "summary" },
          { name: "twitter:site", content: "@yourassignmenthelp.uk" },
          { name: "twitter:title", content: currentMeta.attributes.meta_title },
          { name: "twitter:description", content: currentMeta.attributes.meta_descriptions },
          { name: "twitter:image", content: `/assets/images/logo.svg` },
        ];

        twitterTags.forEach(({ name, content }) => {
          const tag = document.querySelector(`meta[name='${name}']`);
          if (tag) {
            tag.setAttribute("content", content);
          } else {
            const newTag = document.createElement("meta");
            newTag.setAttribute("name", name);
            newTag.setAttribute("content", content);
            document.head.appendChild(newTag);
          }
        });

     
        const canonicalLink = document.querySelector("link[rel='canonical']");
        if (canonicalLink) {
          canonicalLink.setAttribute("href", currentMeta.attributes.url);
        } else {
          const newLink = document.createElement("link");
          newLink.setAttribute("rel", "canonical");
          newLink.setAttribute("href", currentMeta.attributes.url);
          document.head.appendChild(newLink);
        }
      }
    }
  }, [location.pathname, metaData]);

  return (
    <Routes>
      <Route path="*" element={<NotFound />} />
      {/* <Route path="/sample" element={<SamplePage />} /> */}
      <Route element={<Layout />}>
        <Route path="/" element={<Homepage />} />
        <Route path="/experts" element={<Experts />} />
        <Route path="/refund-policy" element={<RefundPolicy />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/fair-use-policy" element={<FairUsePolicy />} />
        <Route path="/free-sample" element={<FreeSamples />} />
        <Route path="/reviews" element={<LazyReviewPage />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/coming-soon" element={<ComingSoon />} />
        <Route path="/grammar-checker" element={<GrammarChecker />} />
        <Route path="/paraphrasing-tool" element={<ParaphrasingTool />} />
        <Route path="/plagiarism-checker" element={<PlagiarismChecker />} />
        <Route path="/factoring-calculator" element={<FactoringCalculator />} />
        <Route path="/equation-solver" element={<EquationSolver />} />
        <Route element={<DashboardLayout />}>
          <Route
            path="/dashboard"
            element={
              <LoginProtected>
                <Dashboard />
              </LoginProtected>
            }
          />
          <Route
            path="/all-orders"
            element={
              <LoginProtected>
                <AllOrders />
              </LoginProtected>
            }
          />

          <Route
            path="/say-no-to-ai"
            element={
              <LoginProtected>
                <SayNoToAi />
              </LoginProtected>
            }
          />
          <Route
            path="/dashboard/grammar-checker"
            element={
              <LoginProtected>
                <DashboardGrammarChecker />
              </LoginProtected>
            }
          />
          <Route
            path="/dashboard/paraphrasing-tool"
            element={
              <LoginProtected>
                <DashboardParaphrasingTool />
              </LoginProtected>
            }
          />
          <Route
            path="/dashboard/plagiarism-checker"
            element={
              <LoginProtected>
                <DashboardPlagiarismChecker />
              </LoginProtected>
            }
          />
          <Route
            path="/dashboard/factoring-calculator"
            element={
              <LoginProtected>
                <DashboardFactoringCalculator />
              </LoginProtected>
            }
          />
          <Route
            path="/dashboard/equation-solver"
            element={
              <LoginProtected>
                <DashboardEquationSolver />
              </LoginProtected>
            }
          />
        </Route>
        <Route
          path="/user-profile"
          element={
            <LoginProtected>
              <UserProfile />
            </LoginProtected>
          }
        />
        <Route
          path="/payment"
          element={
            <Payment />
            // <LoginProtected>
            // </LoginProtected>
          }
        />
        <Route path="/mentor-profile/:id" element={<MentorProfile />} />
        <Route path="/mentor-answer" element={<MentorAnswer />} />
        <Route path="/orders" element={<Orders />} />
        <Route path="/sample" element={<Sample />} />
        <Route path="/sample-answer/:cat_name/:sub_cat_id" element={<SampleAnswer />} />
        <Route path="/sample-answer/:cat_name/:sub_cat_id/:id" element={<AnswerDescriotionSample />} />
        <Route path="/submit-new-assignment" element={<SubmitNewAssigment />} />
        <Route path="/write-my-paper" element={<WriteMyPaper />} />
        <Route path="/mba-essay-writing" element={<MbaAssignment />} />
        <Route path="/write-my-essay-for-me" element={<WriteMyEssay />} />
        <Route path="/cheap-essay-writing" element={<CheapEssay />} />
        <Route path="/get-essay-help" element={<EssayHelp />} />
        <Route path="/research-proposal-writing-service" element={<ResearchProposalWriting />} />
        <Route path="/research-paper-writing" element={<ResearchPaperWriting />} />
        <Route path="/ghost-writer" element={<GhostWriter />} />
        <Route path="/academic-writing" element={<AcademicWriting />} />
        <Route path="/programming-assignment-help" element={<ProgramminHelp />} />
        <Route path="/assessment-help" element={<AssessmentHelp />} />
        <Route path="/do-my-assignment" element={<DoMyAssignment />} />
        <Route path="/college-homework-help" element={<CollegeHomeworkHelp />} />
        <Route path="/urgent-assignment-help" element={<UrgentHelp />} />
        <Route path="/pay-someone-to-do-my-homework" element={<PaySomeoneForHW />} />
        <Route path="/do-my-coursework" element={<DoMyCoursework />} />
        <Route path="/do-my-homework" element={<DoMyHomework />} />
        <Route path="/take-my-online-class" element={<TakeMyOnlineClass />} />
        <Route path="/take-my-online-exam" element={<TakeMyOnlineExam />} />
        <Route path="/dissertation-help" element={<DissertationHelp />} />
        <Route path="/term-paper-help" element={<TermPaperHelp />} />
        <Route path="/homework-help" element={<HomeworkHelp />} />
        <Route path="/case-study-help" element={<CaseStudyHelp />} />
        <Route path="/coursework-help" element={<CourseworkHelp />} />
        <Route path="/thesis-help" element={<ThesisHelp />} />
        <Route path="/powerpoint-presentation-service" element={<Powerpoint />} />
        <Route path="/blogs" element={<Blogs />} />
        <Route path="/blog-details/:id" element={<BlogDetails />} />
        <Route path="/referencing" element={<Referencing />} />
        <Route path="/faqs" element={<FAQ />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/" element={<Homepage />} />
      </Route>
    </Routes>
  );
};

export default AppRoutes;

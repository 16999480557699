import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";

// Utils
import { toast } from "react-hot-toast";

// Constants
import { digits, allowedKeys } from "../../constants/data/data-constants";

// API functions
import { ApiHelperFunction } from "../../helpers/api_helpers";

// Redux actions
import { getContactUsPage } from "../../redux/slices/ContactUsPageSlice";

// Models
import { ApiFuncArgProps } from "../../models/apiFuncHelpers";
import SubmitAssignmentForm from "../components/layout/pages/SubmitAssignmentForm";
// Components
import PageBanner from "../components/layout/pages/PageBanner";

let initialValues = {
  name: "",
  email: "",
  phone_number: "",
  message: "",
};

let createErrors = {
  name: "",
  email: "",
  phone_number: "",
  message: "",
};

var emailReg =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

const FreeSamples = () => {
  const dispatch = useAppDispatch();
  const [userQuery, setuserQuery] = useState(initialValues);
  const [errors, seterrors] = useState(createErrors);

  // Redux stores
  const { contactUsPageStatus, contactUsPageData } = useAppSelector(
    (state) => state.contactUs
  );

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    if (contactUsPageStatus !== "success") {
      dispatch(getContactUsPage());
    }
  }, []);

  const handleChange: React.ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = (event) => {
    setuserQuery({ ...userQuery, [event.target.name]: event.target.value });
  };

  const handleValidation = () => {
    const { name, email, phone_number, message } = userQuery;

    if (name === "") {
      seterrors({ ...createErrors, name: "Name is required." });
      return false;
    }
    if (email === "") {
      seterrors({ ...createErrors, email: "Email cannot be empty." });
      return false;
    }
    if (!emailReg.test(email)) {
      seterrors({
        ...createErrors,
        email: "Please put email in correct format. E.g., abc@xxx.com",
      });
      return false;
    }
    if (
      phone_number !== "" &&
      (phone_number.length < 10 || phone_number.length > 15)
    ) {
      seterrors({
        ...createErrors,
        phone_number: "Phone number must be between 10 to 15 integers.",
      });
      return false;
    }
    if (message.length < 5) {
      seterrors({
        ...createErrors,
        message: "Message cannot be less than 5 characters.",
      });
      return false;
    }

    return true;
  };

  const handleSubmit: React.MouseEventHandler<HTMLDivElement> = async (
    event
  ) => {
    event.preventDefault();

    if (handleValidation()) {
      try {
        let bodyData = { data: userQuery };
        let response = await ApiHelperFunction({
          urlPath: "/contact-us-form-submissions",
          method: "POST",
          data: bodyData,
        } as ApiFuncArgProps);

        if (response.data?.data) {
          setuserQuery(initialValues);
          seterrors(createErrors);
          toast.success("Successfully submitted your query.", {
            duration: 1000,
          });
        } else {
          toast.error("Failed to submit your query.", { duration: 1000 });
        }
      } catch (error: any) {
        toast.error("Something went wrong. Please try again.", {
          duration: 1000,
        });
      }
    }
  };

  return (
    <>
      {/* page banner area start */}
      <PageBanner
        title={
          "Consolidation / Elimination Journal Entry And Calculation Of Goodwill Essay."
        }
        description={contactUsPageData.sec_1_i_banner[0].subheading}
        reviewSites={contactUsPageData.sec_1_i_banner[0].inner_banner_review}
      />
      {/* page banner area end */}

      {/* wrapper area start */}
      <div className="wrapper mb-5">
        <section className="cntcus-sec">
          <div className="container">
            <div className="row">
              <div className="col-lg-7 free-samples-manleft-wrapper">
                <h4>The concept of consolidated financial statements</h4>
                <div className="responsive-table-wrapper">
                  <table>
                    <thead>
                      <tr>
                        <th>Particulars</th>
                        <th>Debit</th>
                        <th>Credit</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Share capital</td>
                        <td> $ 160,000.00</td>
                        <td></td>
                      </tr>

                      <tr>
                        <td>Retained earnings</td>
                        <td> $ 80,000.00</td>
                        <td></td>
                      </tr>

                      <tr>
                        <td>Revaluation surplus</td>
                        <td> $ 100,000.00</td>
                        <td></td>
                      </tr>

                      <tr>
                        <td></td>
                        <td> $ 584,000.00</td>
                        <td></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <h4>Reference</h4>

                <p>
                  Arnold, L.W. and Harris, P., 2013, January. AN EMPIRICAL
                  ANALYSIS OF CORPORATE FINANCIAL CONDITION AFTER ACCOUNTING
                  MALFEASANCE. In Global Conference on Business & Finance
                  Proceedings (Vol. 8, No. 1, p. 130). Institute for Business &
                  Finance Research.
                </p>
                <p>
                  Arnold, L.W., Harris, P. and Liu, M., 2016, January. CORPORATE
                  ACCOUNTING MALFEASANCE: AN OVERVIEW. In Global Conference on
                  Business & Finance Proceedings (Vol. 11, No. 1, p. 202).
                  Institute for Business & Finance Research.
                </p>
              </div>
              <div className="col-lg-5">
                <div className="real-experts-area">
                  <div className="real-experts-bx p-4 mb-5">
                    <div className="home-banner-form">
                      <div className="row justify-content-center">
                        <div>
                          <SubmitAssignmentForm />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="related-topic-box p-4">
                  <h4>Related Topics</h4>
                  <ul>
                    <li>
                      <a href="#">Portfolio management</a>
                    </li>
                    <li>
                      <a href="#">Contingency Aasb</a>
                    </li>
                    <li>
                      <a href="#">Inventory management system</a>
                    </li>
                    <li>
                      <a href="#">Erp system</a>
                    </li>
                    <li>
                      <a href="#">Integrated reporting</a>
                    </li>
                    <li>
                      <a href="#">Bid taxi</a>
                    </li>
                    <li>
                      <a href="#">Billabong</a>
                    </li>
                  </ul>
                </div>

                <div className="related-topic-box p-4">
                  <h4>Related Essays</h4>
                  <ul>
                    <li>
                      <a href="#">
                        Vitiating Factors Of A Contract In Law: An Essay.
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        Possible Partnership Liability Of Violet And Sonny In
                        Essay.
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        Understanding Marketing Objectives, Strategies, Tactics,
                        And Performance Is Crucial For Essay.
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        Essay On Project Governance & Work Breakdown Structure
                        For Geelong-Torquay Train Line.
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        LIDL Supermarket: New Virtual Store Service - Designing
                        An Effective Process
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        Analysis Of Airline Industry And Competitive Scenario: A
                        Case Study Of Virgin Airlines And Qantas Airways Essay.
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        Job Costing, Work-in Process Account, Material And
                        Labour Cost
                      </a>
                    </li>

                    <li>
                      <a href="#">
                        Managing Ethical Risks And Mitigating Accidents In The
                        Oil And Gas Industry Essay.
                      </a>
                    </li>

                    <li>
                      <a href="#">
                        Human Resource Management Plan For Talent Contest
                        Program
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      {/* wrapper area end */}
    </>
  );
};

export default FreeSamples;
